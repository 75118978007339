import React from "react";
import Layout from "./Layout";
import { makeStyles } from "@material-ui/core/styles";
import parse from "html-react-parser";
import PageTitle from "./PageTitle";
import Grid from "@material-ui/core/Grid";
import { Link } from "gatsby";

interface SitemapProps {
  pageContext: {
    catalogCategories: any[];
    iftttArray: any[];
    faqArray: [any];
    articles: any[];
    newsletters: any[];
    compareDeclarativeFrameworks: string[];
  };
}

enum SitemapType {
  Articles,
  Newsletters,
  Libraries,
  API,
  Faq,
  Misc,
  CompareDeclarativeFrameworks,
  DeclarativeFrameworks,
  RssFeed,
}

class SitemapItem {
  title: string;
  slug: string;
  constructor(title: string, slug: string) {
    this.title = title;
    this.slug = slug;
  }
}

function getSectionTitle(type: SitemapType) {
  switch (type) {
    case SitemapType.Articles: {
      return "Top articles about Jetpack Compose";
    }
    case SitemapType.Newsletters: {
      return "Dispatch: Newsletter editions about Jetpack Compose";
    }
    case SitemapType.Libraries: {
      return "Most popular Jetpack Compose Libraries and Projects";
    }
    case SitemapType.API: {
      return "Equivalent Jetpack Compose API's for common Android tasks";
    }
    case SitemapType.Faq: {
      return "Frequently asked questions about Jetpack Compose";
    }
    case SitemapType.CompareDeclarativeFrameworks: {
      return "Compare Jetpack Compose with other declarative frameworks";
    }
    case SitemapType.DeclarativeFrameworks: {
      return "Learn more about the various declarative frameworks";
    }
    case SitemapType.Misc: {
      return "Miscellaneous";
    }
    case SitemapType.RssFeed: {
      return "RSS Feeds";
    }
  }
}

export default function Sitemap(props: SitemapProps) {
  const classes = useStyles();
  let sitemapElementsMap = new Map<SitemapType, SitemapItem[]>();
  sitemapElementsMap.set(
    SitemapType.Faq,
    [
      new SitemapItem(
        "Frequently asked questions about Jetpack Compose",
        "/faq"
      ),
    ].concat(
      props.pageContext.faqArray.map((faq) => {
        const question = String(faq.question);
        const questionPattern = /^(.+?)(?:\s*<.*)?$/;
        const match = question.match(questionPattern);
        const parsedQuestion = match[1];
        // Get rid of the question mark and replace spaces with "-"
        const normalizedQuestion = parsedQuestion
          .replace(/\?/g, "")
          .replace(/ /g, "-");
        return new SitemapItem(faq.question, `/${normalizedQuestion}`);
      })
    )
  );

  sitemapElementsMap.set(
    SitemapType.Libraries,
    [
      new SitemapItem(
        `Top libraries, projects & examples in Jetpack Compose`,
        "/compose-catalog"
      ),
    ].concat(
      props.pageContext.catalogCategories.map((category) => {
        const title = `${category} libraries in Jetpack Compose`;
        const url = title.split(" ").join("-");
        return new SitemapItem(`Top ${title}`, `/${url}`);
      })
    )
  );

  sitemapElementsMap.set(
    SitemapType.API,
    [
      new SitemapItem(
        "What is the equivalent of `X` in Jetpack Compose",
        "/What-is-the-equivalent-of-X-in-Jetpack-Compose"
      ),
      new SitemapItem(
        "What's the equivalent in Jetpack Compose",
        "/What-is-the-equivalent-of--in-Jetpack-Compose"
      ),
    ].concat(
      props.pageContext.iftttArray.map((ifttt) => {
        const title = `What is the equivalent of ${ifttt.classicAndroid} in Jetpack Compose`;
        const url = title.split(" ").join("-");
        return new SitemapItem(`${title}`, `/${url}`);
      })
    )
  );

  sitemapElementsMap.set(
    SitemapType.Articles,
    [
      new SitemapItem(
        "Top articles and blogs about Jetpack Compose",
        "/articles"
      ),
    ].concat(
      props.pageContext.articles.map((article) => {
        return new SitemapItem(
          article.frontmatter.title,
          article.frontmatter.slug
        );
      })
    )
  );

  sitemapElementsMap.set(
    SitemapType.Newsletters,
    [
      new SitemapItem(
        "The essential Jetpack Compose newsletter that delivers just the bytes that matter",
        "/newsletter"
      ),
    ].concat(
      props.pageContext.newsletters.map((newsletter) => {
        return new SitemapItem(
          newsletter.frontmatter.title,
          newsletter.frontmatter.slug
        );
      })
    )
  );

  const declarativeFrameworkComparison =
    props.pageContext.compareDeclarativeFrameworks.filter((framework) =>
      framework.includes("-vs-")
    );
  const declarativeFrameworks =
    props.pageContext.compareDeclarativeFrameworks.filter(
      (framework) => !framework.includes("-vs-")
    );

  sitemapElementsMap.set(
    SitemapType.DeclarativeFrameworks,
    [
      new SitemapItem(
        "Compare Declarative Frameworks",
        `/compare-declarative-frameworks`
      ),
    ].concat(
      declarativeFrameworks.map((comparison) => {
        return new SitemapItem(
          comparison,
          `/compare-declarative-frameworks/${comparison}`
        );
      })
    )
  );

  sitemapElementsMap.set(
    SitemapType.CompareDeclarativeFrameworks,
    declarativeFrameworkComparison.map((comparison) => {
      return new SitemapItem(
        comparison.replaceAll("-", " "),
        `/compare-declarative-frameworks/${comparison}`
      );
    })
  );

  sitemapElementsMap.set(SitemapType.RssFeed, [
    new SitemapItem("Articles RSS Feed", "/rss-articles.xml"),
    new SitemapItem("Newsletter - Dispatch RSS Feed", "/rss-newsletter.xml"),
  ]);

  sitemapElementsMap.set(SitemapType.Misc, [
    new SitemapItem(
      "Subscribe to JetpackCompose.app, The #1 website for all things Jetpack Compose",
      "/subscribe"
    ),
    new SitemapItem(
      "Quick Bites - Learn more about the core concepts of Jetpack Compose right from your Twitter feed",
      "/quick-bites"
    ),
    new SitemapItem(
      "How many Android Developers have we helped at JetpackCompose.app?",
      "/impact"
    ),
    // new SitemapItem("Early Access", "/early-access"),
  ]);

  return (
    <>
      <Layout
        maxWidth="lg"
        pageTitle="Sitemap - JetpackCompose.app"
        pageDescription="The JetpackCompose.app sitemap includes links to some of the most popular Jetpack Compose libraries, faq's, articles, snippets and projects."
        pageSlug="/sitemap"
        pageImageUrl="/landing.png"
        seoImageUrl="/landing.png"
      >
        <div className={classes.pageRoot}>
          <PageTitle header={"Sitemap"} subheader={""} />
          {Array.from(sitemapElementsMap.entries()).map((entry) => {
            return (
              <Grid
                container
                xs={12}
                spacing={3}
                className={classes.sectionContainer}
              >
                <Grid item xs={12}>
                  <div className={classes.sectionHeading}>
                    {getSectionTitle(entry[0])}
                  </div>
                </Grid>
                {entry[1].map((item) => {
                  return (
                    <Grid item xs={6} md={4}>
                      <div className={classes.link}>
                        <Link to={item.slug}>{parse(item.title)}</Link>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            );
          })}
        </div>
      </Layout>
    </>
  );
}

const useStyles = makeStyles({
  pageRoot: {},
  sectionContainer: {
    marginBottom: 32,
    marginTop: 32,
  },
  sectionHeading: {
    fontSize: 32,
    marginButtom: 16,
    fontFamily: "Nunito Sans",
    fontWeight: 600,
    color: "#222222",
    textRendering: "optimizeSpeed",
  },
  link: {
    fontSize: 20,
    fontFamily: "Nunito Sans",
    fontWeight: 400,
    "& a": {
      color: "#222",
      "&:hover": {
        color: "#4636f7",
      },
    },
  },
});
